import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      loadingQuery: false
    }
  },
  computed: {
    isAssetFilterPage() {
      return this.$route.name === 'show-asset-filter'
    },
    ...mapGetters('router', ['query'])
  },
  watch: {
    search(search) {
      if (!this.loadingQuery) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.addQueryParameter({ page: 1, search })
          this.debouncing = false
        }, 300)
      }
    },
    'options.page'(page) {
      this.addQueryParameter({ page })
    },
    'options.itemsPerPage'(itemsPerPage) {
      this.addQueryParameter({ itemsPerPage })
    },
    filter: {
      handler(filter) {
        if (!_.isEmpty(filter) && filter.filters.length > 0 && !this.isAssetFilterPage) {
          this.addQueryParameter({ filter: JSON.stringify(filter) })
        } else {
          this.removeQueryParameter('filter')
        }
      },
      deep: true
    },
    query: {
      handler() {
        this.loadFromQuery()
      },
      deep: true
    },
    '$route.query': {
      handler() {
        this.updateQuery(this.$route.query)
        this.loadFromQuery()
      },
      deep: true
    }
  },
  methods: {
    loadFromQuery() {
      if (this.loadingQuery) {
        return
      }
      this.loadingQuery = true

      const q = Object.assign({}, this.query)

      // page
      const page = parseInt(q.page)
      if (Number.isInteger(page)) {
        this.options.page = page
      } else {
        this.options.page = 1
      }

      const itemsPerPage = parseInt(q.itemsPerPage)
      if (Number.isInteger(itemsPerPage)) {
        this.options.itemsPerPage = itemsPerPage
      } else {
        this.options.itemsPerPage = 25
        this.removeQueryParameter('itemsPerPage')
      }

      // search
      if (q.search) {
        this.search = q.search
      } else {
        this.search = ''
        this.removeQueryParameter('search')
      }

      // filter
      if (!_.isEmpty(q.filter)) {
        try {
          const f = JSON.parse(q.filter)
          if (_.isEmpty(f)) {
            this.removeQueryParameter('filter')
          } else {
            this.filter = f
          }
        } catch {
          // remove the key if failed to parse filters
          console.warn('Failed to parse filter', q.filter)
          this.removeQueryParameter('filter')
        }
      } else {
        if (!this.isAssetFilterPage) {
          this.filter = {}
          this.removeQueryParameter('filter')
        }
      }

      // filters
      if (!_.isEmpty(q.filters)) {
        try {
          const f = JSON.parse(q.filters)
          if (!Array.isArray(f) || f.length === 0) {
            this.removeQueryParameter('filters')
          } else {
            this.filters = f
          }
        } catch {
          // remove the key if failed to parse filters
          console.warn('Failed to parse filter', q.filters)
          this.removeQueryParameter('filters')
        }
      } else {
        if (!this.isAssetFilterPage) {
          this.filters = []
          this.removeQueryParameter('filters')
        }
      }

      this.$nextTick(() => (this.loadingQuery = false))
    },
    ...mapActions('router', ['addQueryParameter', 'removeQueryParameter', 'updateQuery'])
  },
  created() {
    this.loadFromQuery()
  }
}
