import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination
  },
  data() {
    return {
      users: [],
      usersCount: 0,

      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Employee ID', value: 'employeeId' },
        { text: 'SamAccountName', value: 'samAccountName' },
        { text: 'Role(s)', value: 'roles' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.usersCount === 0
        ? 1
        : Math.ceil(this.usersCount / this.options.itemsPerPage)
    }
  },
  watch: {
    options: {
      handler() {
        this.$apollo.queries.intraAccounts.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.intraAccounts.refetch()
    },
    // Everytime the route changes, reset search
    $route(to, from) {
      if (to.path !== from.path) {
        this.search = ''
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path) {
      this.search = ''
    }
    next()
  },
  methods: {
    getOrderBy() {
      const { sortBy, sortDesc } = this.options
      const orderBy = []
      for (let i = 0; i < sortBy.length; i++) {
        let path = sortBy[i]
        if (path === 'roles') {
          path = 'groups'
        }
        orderBy.push({ path, descending: sortDesc[i] })
      }
      return orderBy
    }
  },
  apollo: {
    intraAccounts: {
      query() {
        const fields = this.getFieldsGql('read', 'IntraAccount', [
          'id',
          'name',
          'employeeId',
          'samAccountName',
          'deletedExternally',
          { name: 'roles', type: 'Role', fields: ['name'] }
        ])
        return this.$gql`
          query SearchIntraAccounts(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
            $where: [WhereExpression]
          ) {
            intraAccounts(search: $q, take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take: this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip: this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy(),
          where: [{ path: 'deletedExternally', value: 'false' }]
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          !this.ability.can('read', 'IntraAccount')
        )
      },
      result({ data, error }) {
        if (data && data.intraAccounts) {
          this.users = data.intraAccounts.items
          this.usersCount = data.intraAccounts.totalCount
        } else if (error) {
          this.graphQLOnError(`Failed to get list of users. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'IntraAccount')) {
      this.$router.push('/')
    }
  }
}
